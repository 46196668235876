import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

export const SipRedLogo = () => {
  return (
    <Typography
      component="h1"
      variant="h4"
      role="heading"
      sx={{
        width: 193,
        height: 193,
        color: 'rgba(0,0,0,0)',
        userSelect: 'none',
        background: 'url(/appstatic/sip-logo.svg) center center no-repeat',
      }}
    >
      <Link
        to="/login"
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          color: 'rgba(0,0,0,0)',
        }}
      >
        SIP
      </Link>
    </Typography>
  )
}
