/**
 * List of public paths that do not require authentication
 *
 * @see src/root/rootLoader.ts
 */
export const publicPaths = [
  '/login',
  '/lost-password',
  '/lost-password-reset',
  '/request-access',
  '/activate-account',
]

/**
 * Check if provided pathname is public
 *
 * @param pathname string
 * @returns boolean
 */
export const isPublicPath = (pathname: string) => publicPaths.includes(pathname)
