import { print } from 'graphql'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { authStore } from 'auth'
import { GRAPHQL_PRIVATE_URL, GRAPHQL_PUBLIC_URL } from 'env'
import { fetchRefreshToken } from 'auth/fetchRefreshToken'
import { i18n } from 'i18n'

type Result<TData = any> = {
  data?: TData
  errors?: Error[]
}

export async function executePublic<
  TData = any,
  TVariables = Record<string, never>
>(
  doc: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables
): Promise<Result<TData>> {
  const query = print(doc)

  const result = await fetch(GRAPHQL_PUBLIC_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Language': i18n.language,
    },
    body: JSON.stringify({ query, variables }),
  })

  return result?.json() || null
}

type FetchPrivateProps = {
  query: string
  variables?: any
  token: string
}

function fetchPrivate({ query, variables, token }: FetchPrivateProps) {
  return fetch(GRAPHQL_PRIVATE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Language': i18n.language,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ query, variables }),
  })
}

export async function executePrivate<
  TData = any,
  TVariables = Record<string, never>
>(
  doc: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables
): Promise<Result<TData>> {
  const auth = authStore.getState()
  const { token, refreshToken } = auth

  if (!token) throw new Error('Missing token')

  const query = print(doc)

  const result = await fetchPrivate({ query, variables, token })

  // refresh token if expired
  if (result.status === 401) {
    if (refreshToken) {
      const next = await fetchRefreshToken({ refreshToken })
      if (next?.token && next?.refreshToken) {
        auth.setToken(next.token, next.refreshToken)
        const result = await fetchPrivate({
          query,
          variables,
          token: next.token,
        })
        return result.json()
      }
    }
    auth.setError(i18n.t('auth.token_expired'))
    auth.logout()
    return { errors: [new Error('Unauthorized')] }
  }

  return result.json()
}

export function handleError(e: unknown) {
  return {
    data: null,
    errors: null,
    error: e instanceof Error ? e.message : 'Bad Request',
  }
}
