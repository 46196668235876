import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { authStore } from './store'

export const useRedirectToLogin = () => {
  const token = authStore.use.token()
  const user = authStore.use.user()

  const navigate = useNavigate()

  useEffect(() => {
    if (!token || !user) {
      //console.log('should redirect to login')
      navigate('/login')
    }
  }, [token, user, navigate])
}

export const useRedirectToApp = () => {
  const user = authStore.use.user()

  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      //console.log('should redirect to app')
      navigate('/')
    }
  }, [user, navigate])
}
