import Typography from '@mui/material/Typography'

type FormErrorProps = {
  error?: string | string[] | null
}

export function FormError({ error }: FormErrorProps) {
  if (!error) return null
  return (
    <Typography variant="body2" sx={{ color: 'primary.main', ml: 1.75 }}>
      {error.toString()}
    </Typography>
  )
}
