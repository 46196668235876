import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useRevalidator } from 'react-router-dom'

import Menu from '@mui/material/Menu'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import { supportedLngs } from 'i18n'
import { createFlagElement } from './flags'
import { useDropdownMenu } from 'app/hooks'

const langs: Record<string, string> = {
  en: 'English',
  sl_SI: 'Slovenščina',
  de_DE: 'Deutsch',
  fr_FR: 'Français',
  pl_PL: 'Polski',
}

export const LanguageButton = (props: IconButtonProps<'button'>) => {
  const { i18n } = useTranslation()
  const menu = useDropdownMenu()

  const revalidator = useRevalidator()

  const handleItemClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      i18n.changeLanguage(e.currentTarget.dataset.lng)
      revalidator.revalidate()
    },
    [i18n, revalidator]
  )

  return (
    <>
      <IconButton
        aria-controls={menu.open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menu.open ? 'true' : undefined}
        color="inherit"
        size="large"
        onClick={menu.handleClick}
        {...props}
      >
        {createFlagElement(i18n.language)}
      </IconButton>
      <Menu
        anchorEl={menu.anchorEl}
        id="language-menu"
        open={menu.open}
        onClose={menu.handleClose}
        onClick={menu.handleClose}
      >
        {supportedLngs.map((lng) => (
          <MenuItem key={lng} data-lng={lng} onClick={handleItemClick}>
            <ListItemIcon>{createFlagElement(lng)}</ListItemIcon>
            {langs[lng]}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
