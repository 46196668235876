import { styled } from '@mui/material/styles'
import MuiField, { TextFieldProps as MuiProps } from '@mui/material/TextField'
import Box, { BoxProps } from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import ErrorIcon from '@mui/icons-material/Error'
import { TextFieldError } from './TextFieldError'

const BaseTextField = styled(MuiField)<MuiProps>(({ theme }) => {
  return {
    marginBottom: 32,
    '& .MuiTextField-root': {
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
    },
    '& .MuiInputBase-root': {
      background: 'white',
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px',
      '&:hover, &:focus-within': {
        background: 'white',
      },
      '&.Mui-disabled': {
        background: theme.palette.action.disabledBackground,
      },
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  }
})

export type TextFieldProps = Omit<MuiProps, 'error' | 'InputProps'> & {
  error?: string | string[]
  boxProps?: BoxProps
}

export const TextField = ({ error, boxProps, ...props }: TextFieldProps) => {
  const { sx: boxSx, ...boxRest } = boxProps || {}
  return (
    <Box
      sx={[
        { position: 'relative' },
        props.fullWidth && { width: '100%' },
        ...(Array.isArray(boxSx) ? boxSx : [boxSx]),
      ]}
      {...boxRest}
    >
      <BaseTextField
        {...props}
        error={!!error}
        InputProps={
          error
            ? {
                endAdornment: (
                  <Tooltip title={error.toString()}>
                    <ErrorIcon
                      sx={(theme) => ({
                        cursor: 'auto',
                        color: theme.palette.primary.main,
                      })}
                    />
                  </Tooltip>
                ),
              }
            : undefined
        }
      />
      {error && (
        <TextFieldError title={error.toString()}>
          {error.toString()}
        </TextFieldError>
      )}
    </Box>
  )
}
