import { REFRESH_TOKEN_URL } from 'env'

type Props = {
  refreshToken: string
}

export async function fetchRefreshToken({ refreshToken }: Props) {
  //console.log('refresh token')
  const result = await fetch(
    `${REFRESH_TOKEN_URL}?refresh_token=${refreshToken}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
      },
    }
  )

  const json = await result.json()

  // user has 2FA disabled, so we get a token back
  if (json.token && json.refresh_token) {
    //console.log('refresh token success')
    return {
      token: json.token,
      refreshToken: json.refresh_token,
    }
  }

  //console.log('refresh token error', json.message || 'Invalid refresh token')

  throw new Error(json.message || 'Invalid refresh token')
}
