import LinearProgress from '@mui/material/LinearProgress'

type Props = {
  show: boolean
}

export const Progress = ({ show }: Props) => {
  if (!show) return null

  return (
    <LinearProgress
      color="primary"
      sx={(theme) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: theme.zIndex.drawer + 1,
      })}
    />
  )
}
