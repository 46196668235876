import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { LOGROCKET_APP_ID, NODE_ENV, SENTRY_DSN } from 'env'
//import { ReportHandler } from 'web-vitals';

if (NODE_ENV === 'production' || NODE_ENV === 'staging') {
  /**
   * Setup Sentry
   *
   * @see https://optiweb.atlassian.net/browse/SIP-162
   * @see https://docs.sentry.io/platforms/javascript/guides/react/
   * @see https://sip-partner-portal-bojan.sentry.io/
   */
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  /**
   * Setup LogRocket
   *
   * @see https://optiweb.atlassian.net/browse/SIP-162
   * @see https://docs.logrocket.com/reference/init
   * @see https://app.logrocket.com/pnqh3f/sip-partner-portal
   */
  setupLogRocketReact(LogRocket)
  LogRocket.init(LOGROCKET_APP_ID)

  /**
   * If you want to start measuring performance in your app, pass a function
   * to log results (for example: reportWebVitals(console.log))
   * or send to an analytics endpoint.
   *
   * @see https://bit.ly/CRA-vitals
   */
  //reportWebVitals(console.log)
  /*
  const reportWebVitals = (onPerfEntry?: ReportHandler) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
      import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      });
    }
  };
  */
}
