import CardMedia, { CardMediaProps } from '@mui/material/CardMedia'
import { StylesFn } from 'theme'

const styles: StylesFn = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 80,
  color: theme.palette.lightGrey.main,
})

type Props = CardMediaProps

export const CardMediaIcon = ({ children, sx, ...props }: Props) => {
  return (
    <CardMedia sx={[styles, ...(Array.isArray(sx) ? sx : [sx])]} {...props}>
      {children}
    </CardMedia>
  )
}
