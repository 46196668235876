import Card, { CardProps } from '@mui/material/Card'
import { StylesFn } from 'theme'

const styles: StylesFn = (theme) => ({
  background: theme.palette.darkGrey.main,
  color: theme.palette.darkGrey.contrastText,
  '& .MuiCardHeader-content': {
    overflow: 'hidden',
  },
  '& .MuiCardHeader-avatar .MuiAvatar-root': {
    color: theme.palette.darkGrey.main,
    backgroundColor: theme.palette.common.white,
  },

  '& .MuiCardHeader-title': {
    color: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },
  '& .MuiCardHeader-title a': {
    color: 'inherit',
    textDecoration: 'none',
  },
  '& .MuiCardHeader-title a:hover': {
    textDecoration: 'underline',
  },
  '& .MuiCardHeader-subheader': {
    color: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    fontWeight: 300,
    opacity: 0.6,
  },
  '& .MuiCardHeader-action': {
    margin: 0,
  },
  '& .MuiCardMedia-root': {
    aspectRatio: 16 / 9,
    background: theme.palette.darkGrey.light,
  },
})

export const DarkCard = <T extends React.ElementType>({
  children,
  sx,
  ...props
}: CardProps<T>) => {
  return (
    <Card
      sx={[styles, ...(Array.isArray(sx) ? sx : [sx])]}
      elevation={10}
      {...props}
    >
      {children}
    </Card>
  )
}
