import MuiCheckbox from '@mui/material/Checkbox'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'

type Props = FormControlProps & {
  label: string
  name: string
}

export const Checkbox = ({ label, name, sx, ...props }: Props) => {
  return (
    <FormControl
      fullWidth
      sx={[{ color: '#fff', mb: 4, pl: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...props}
    >
      <FormControlLabel
        control={
          <MuiCheckbox
            color="primary"
            sx={(theme) => ({ color: theme.palette.primary.main })}
            name={name}
          />
        }
        label={label}
        sx={{ userSelect: 'none', color: '#fff' }}
      />
    </FormControl>
  )
}
