import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
//import reportWebVitals from './reportWebVitals'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import { FallbackElement } from 'components/FallbackElement'
import { authStore } from 'auth'
import { theme, globalStyles } from 'theme'
import 'fonts'
import 'i18n'
import 'logging'

// initialize auth
authStore.getState().initialize()

// lazy load router
const Router = lazy(() => import('./router'))

// wait for auth, before rendering router
const WaitForAuth = () => {
  const initialized = authStore.use.initialized()
  return initialized ? <Router /> : <FallbackElement />
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// render react app
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      <Suspense fallback={<FallbackElement />}>
        <WaitForAuth />
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log)
