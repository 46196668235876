import Box, { BoxProps } from '@mui/material/Box'

export const Container = ({ children, sx, ...props }: BoxProps<'div'>) => {
  return (
    <Box
      sx={[
        (theme) => ({
          width: '100%',
          margin: '0 auto',
          maxWidth: theme.container.maxWidth,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {children}
    </Box>
  )
}
