import Typography, { TypographyProps } from '@mui/material/Typography'

type Props = TypographyProps<'h1'> & {
  nomargin?: boolean
}

export const Title = ({ children, nomargin = false, ...props }: Props) => {
  return (
    <Typography
      component="h1"
      variant="h4"
      sx={{
        mb: nomargin ? 0 : { xs: 2, md: 3 },
        fontSize: { xs: '1.75rem', sm: '1.875rem', md: '2.125rem' },
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}
