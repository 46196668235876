import en from './en_GB.png'
import fr_FR from './fr_FR.png'
import de_DE from './de_DE.png'
import sl_SI from './sl_SI.png'
import pl_PL from './pl_PL.png'
import Box from '@mui/material/Box'

const flags: Record<string, string> = {
  en,
  sl_SI,
  de_DE,
  fr_FR,
  pl_PL,
}

/**
 * Create a flag element for the given language.
 * 
 * @see https://zastave.vlajky.org/evropa/
 */
export const createFlagElement = (lng: string) => (
  <Box
    sx={{
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 24,
      height: 24,
    }}
  >
    <img
      src={flags[lng]}
      alt={lng}
      width="24"
      height="auto"
      style={{ boxShadow: '0px 0px 1px rgba(0,0,0,0.5)' }}
    />
  </Box>
)
