import Box from '@mui/material/Box'
import { useRouteError } from 'react-router-dom'
import { AuthHeader, AuthTitle } from './components'
import { ErrorStack } from 'error'

export const AuthError = () => {
  const error = useRouteError() as Error
  return (
    <Box
      sx={{
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        px: { xs: 3, md: 6 },
        py: 6,
        background: (theme) => theme.palette.darkGrey.main,
      }}
    >
      <AuthHeader />
      <AuthTitle>Auth Error</AuthTitle>
      <ErrorStack error={error} />
    </Box>
  )
}
