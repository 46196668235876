import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

/**
 * Fallback element on <RouterProvider>
 *
 * Do NOT use any components that use theme inside FallbackElement,
 * because ThemeProvider is not yet ready at this point.
 *
 * @see https://reactrouter.com/en/main/routers/router-provider#fallbackelement
 */

export const FallbackElement = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress color="primary" size={60} />
    </Box>
  )
}
