import Box, { BoxProps } from '@mui/material/Box'

export const AuthContent = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        //border: '1px solid red',
        //display: 'flex',
        //flexDirection: 'column',
        //gap: 3,
        width: '100%',
        maxWidth: 302,
      }}
    >
      {children}
    </Box>
  )
}
