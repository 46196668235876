import { StoreApi, UseBoundStore, useStore } from 'zustand'

/**
 * Auto generate store selectors
 *
 * @see https://docs.pmnd.rs/zustand/guides/auto-generating-selectors
 */

export type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S
) => {
  let store = _store as WithSelectors<typeof _store>
  store.use = {}
  for (let k of Object.keys(store.getState())) {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(store.use as any)[k] = () => store((s) => s[k as keyof typeof s])
  }

  return store
}

/**
 * Auto generate vanilla store selectors
 *
 * @see https://docs.pmnd.rs/zustand/guides/auto-generating-selectors#vanilla-store
 */

export type WithVanillaSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never

export const createVanillaSelectors = <S extends StoreApi<object>>(
  _store: S
) => {
  const store = _store as WithVanillaSelectors<typeof _store>
  store.use = {}
  for (const k of Object.keys(store.getState())) {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(store.use as any)[k] = () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useStore(_store, (s) => s[k as keyof typeof s])
  }

  return store
}
