import { useEffect } from 'react'

const onKeyDown = (e: KeyboardEvent) => {
  if (e.ctrlKey && e.key === 'p') {
    alert('Print disabled!')
    e.preventDefault()
    e.stopImmediatePropagation()
  }
}

const onKeyPress = (e: KeyboardEvent) => {
  if (e.key === 'PrintScreen') {
    alert('Screenshots disabled!')
    e.preventDefault()
    e.stopImmediatePropagation()
  }
}

const onKeyUp = (e: KeyboardEvent) => {
  if (e.key === 'PrintScreen') {
    e.preventDefault()
    navigator.clipboard.writeText('')
    alert('Screenshots disabled!')
  }
}

export const useDisablePrintScreen = () => {
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keypress', onKeyPress)
    document.addEventListener('keyup', onKeyUp)
  }, [])
}
