import { useEffect, useState } from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend, { HttpBackendOptions } from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { TRANSLATIONS_URL } from 'env'

export const fallbackLng = 'sl_SI'
export const supportedLngs = ['sl_SI', 'en', 'de_DE', 'fr_FR', 'pl_PL']

export const getLanguagePath = (lng: string | null) => {
  const language = lng && supportedLngs.includes(lng) ? lng : fallbackLng
  const path = language?.split('_').pop()?.toUpperCase()
  return path
}

if (process.env.NODE_ENV === 'test') {
  /**
   * React i18next test implementation
   *
   * @todo figure out how to test without this
   * @see https://react.i18next.com/misc/testing#example-configuration-for-testing
   */
  i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['translationsNS'],
    defaultNS: 'translationsNS',

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    resources: { en: { translationsNS: {} } },
  })
} else {
  /**
   * React i18next implementation
   *
   * @see https://react.i18next.com/getting-started
   * @see https://www.i18next.com/overview/getting-started
   *
   * Language detection using browser detector
   *
   * @see https://github.com/i18next/i18next-browser-languageDetector
   *
   * Load translations using http backend
   *
   * @see https://github.com/i18next/i18next-http-backend
   * @see https://www.i18next.com/how-to/add-or-load-translations#load-using-a-backend-plugin
   */
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init<HttpBackendOptions>({
      debug: false,
      fallbackLng,
      supportedLngs,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        loadPath: TRANSLATIONS_URL,
      },
    })
}

export { i18n }

export function getLanguageFromLocalStorage() {
  return localStorage.getItem('i18nextLng')
}

export const useI18nReady = () => {
  const [i18nReady, setI18nReady] = useState(i18n.isInitialized ? true : false)

  useEffect(() => {
    if (i18n.isInitialized && !i18nReady) {
      setI18nReady(true)
    } else {
      i18n.on('initialized', () => setI18nReady(true))
    }
  }, [i18nReady])

  return i18nReady
}
