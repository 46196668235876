import type { SxProps } from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'
import { StylesFn } from 'theme'

const defaultStyles: SxProps = {
  flex: 1,
  p: 3,
  minHeight: 300,
  overflow: 'hidden',
}

const fullStyles: StylesFn = ({ appbar, header }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  flex: 1,
  p: 3,
  width: '100%',
  height: {
    xs: `calc(100vh - ${appbar.minHeight.xs}px - ${header.minHeight.xs}px)`,
    sm: `calc(100vh - ${appbar.minHeight.sm}px - ${header.minHeight.sm}px)`,
    md: `calc(100vh - ${appbar.minHeight.md}px - ${header.minHeight.md}px)`,
  },
})

export type ContentProps = BoxProps<'main'> & {
  variant?: 'default' | 'full'
}

export const Content = ({
  children,
  sx,
  variant = 'default',
  ...props
}: ContentProps) => {
  return (
    <Box
      component="main"
      className="app-content"
      sx={[
        variant === 'default' ? defaultStyles : fullStyles,
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {children}
    </Box>
  )
}
