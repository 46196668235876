import { forwardRef } from 'react'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom'

export type NavLinkProps = RouterNavLinkProps & MuiLinkProps

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  function NavLink({ children, ...props }, ref) {
    return (
      <MuiLink component={RouterNavLink} {...props} ref={ref}>
        {children}
      </MuiLink>
    )
  }
)
