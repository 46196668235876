import Typography, { TypographyProps } from '@mui/material/Typography'

type Props = Omit<TypographyProps<'p'>, 'component'>

export const AuthTitle = ({ children, sx, ...props }: Props) => {
  return (
    <Typography
      paragraph
      variant="h6"
      sx={[
        { mb: 4, textTransform: 'uppercase' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {children}
    </Typography>
  )
}
