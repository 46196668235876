import Box, { BoxProps } from '@mui/material/Box'
import { SipRedLogo } from './SipRedLogo'
import { LanguageButton } from 'language'

export const AuthHeader = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        //justifyContent: 'space-between',
        gap: 1.5,
        mb: 6,
      }}
    >
      <SipRedLogo />
      <LanguageButton />
    </Box>
  )
}
