import Typography from '@mui/material/Typography'

export type ErrorStackProps = {
  error: Error
}

export const ErrorStack = ({ error }: ErrorStackProps) => {
  return (
    <Typography
      component="pre"
      fontFamily="monospace"
      fontSize={14}
      sx={{ overflowX: 'auto', overflowY: 'hidden' }}
    >
      {error.stack}
    </Typography>
  )
}
