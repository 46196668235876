import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

import { useRedirectToApp } from './hooks'
import { authStore } from './store'

/**
 * Auth layout - visible to public
 *
 * Will trigger redirect to / if user is present in auth state
 */

export const AuthLayout = () => {
  const { t } = useTranslation()

  const error = authStore.use.error()
  const setError = authStore.use.setError()

  useRedirectToApp()

  return (
    <>
      <Box
        component="main"
        className="auth-layout-wrapper"
        sx={{
          display: 'flex',
          flex: 1,
          background:
            'url(/appstatic/sip-login-70.webp) center center no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Box
          className="auth-layout"
          sx={{
            position: 'relative',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            maxWidth: { sm: 400 },
            p: { xs: 2, md: 6 },
            pt: { xs: 2, sm: 6 },
            pb: { xs: 6, md: 6 },
            background: (theme) => theme.palette.darkGrey.main,
          }}
        >
          <Outlet />
        </Box>
      </Box>
      {error && (
        <Dialog
          open
          onClose={() => setError(null)}
          aria-labelledby="auth-error-title"
          aria-describedby="auth-error-text"
        >
          <DialogTitle id="auth-error-title">{t('auth.error')}</DialogTitle>
          <DialogContent sx={{ minWidth: 300 }}>
            <DialogContentText id="auth-error-text">{error}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setError(null)} autoFocus>
              {t('auth.close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
