function env(name: string): string {
  const value = process.env[name]
  if (!value) {
    throw new Error(`Missing ${name} env variable!`)
  }
  return value
}

export const NODE_ENV = env('NODE_ENV')
export const API_URL = env('REACT_APP_API_URL')
export const LOGIN_URL = env('REACT_APP_LOGIN_URL')
export const TWO_FACTOR_URL = env('REACT_APP_TWO_FACTOR_URL')
export const REFRESH_TOKEN_URL = env('REACT_APP_REFRESH_TOKEN_URL')
export const TRANSLATIONS_URL = env('REACT_APP_TRANSLATIONS_URL')
export const GRAPHQL_PUBLIC_URL = env('REACT_APP_GRAPHQL_PUBLIC_URL')
export const GRAPHQL_PRIVATE_URL = env('REACT_APP_GRAPHQL_PRIVATE_URL')
export const MENU_FOLDER_ID = parseInt(env('REACT_APP_MENU_FOLDER_ID'))
export const PRODUCTS_FOLDER_ID = parseInt(env('REACT_APP_PRODUCTS_FOLDER_ID'))
export const SENTRY_DSN = env('REACT_APP_SENTRY_DSN')
export const LOGROCKET_APP_ID = env('REACT_APP_LOGROCKET_APP_ID')
export const RECAPTCHA_SITE_KEY = env('REACT_APP_RECAPTCHA_SITE_KEY')

if (NODE_ENV === 'development') {
  console.log(
    JSON.stringify(
      {
        API_URL,
        LOGIN_URL,
        TWO_FACTOR_URL,
        REFRESH_TOKEN_URL,
        GRAPHQL_PUBLIC_URL,
        GRAPHQL_PRIVATE_URL,
        TRANSLATIONS_URL,
      },
      null,
      2
    )
  )
}
