import { createStore } from 'zustand'
import { UserFragment } from 'app/types'
import { createVanillaSelectors } from 'utils/zustand'
import { getUser } from 'app/getUser'

const TOKEN = 'token'
const REFRESH_TOKEN = 'refreshToken'

/**
 * Auth Store
 */

interface AuthState {
  initialized: boolean
  token: string | null
  refreshToken: string | null
  user: UserFragment | null
  error: string | null
  initialize: () => void
  setInitialized: (initialized: boolean) => void
  setToken: (token: string, refreshToken: string | null) => void
  removeToken: () => void
  setUser: (user: UserFragment) => void
  removeUser: () => void
  logout: () => void
  setError: (error: string | null) => void
}

export const authStore = createVanillaSelectors(
  createStore<AuthState>()((set) => ({
    initialized: false,
    token: localStorage.getItem(TOKEN),
    refreshToken: localStorage.getItem(REFRESH_TOKEN),
    user: null,
    error: null,
    setError: (error) => set(() => ({ error })),
    initialize: async () => {
      try {
        const user = await getUser()
        //console.log('initialize auth with user', user)
        set(() => ({ user, initialized: true }))
      } catch {
        //console.log('initialize auth without user')
        set(() => ({ user: null, initialized: true }))
      }
    },
    setInitialized: (initialized) => set(() => ({ initialized })),
    setToken: (token, refreshToken) => {
      localStorage.setItem(TOKEN, token)
      if (refreshToken) {
        localStorage.setItem(REFRESH_TOKEN, refreshToken)
      } else {
        localStorage.removeItem(REFRESH_TOKEN)
      }
      set(() => ({ token, refreshToken }))
    },
    removeToken: () => {
      localStorage.removeItem(TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
      set(() => ({ token: null, refreshToken: null }))
    },
    setUser: (user) => set(() => ({ user })),
    removeUser: () => set(() => ({ user: null })),
    logout: () => {
      localStorage.removeItem(TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
      set(() => ({ token: null, refreshToken: null, user: null }))
    },
  }))
)
