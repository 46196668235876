import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const textFieldStyles = {
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  padding: '3px 12px',
  zIndex: 1,
  fontSize: '0.75rem',
  /**
   * Improve contrast ratio
   *
   * Text and images of text have a contrast ratio of at least 4.5:1.
   *
   * primary.light = ratio 2.73
   *
   * @todo contrast ratio
   * @see https://www.siegemedia.com/contrast-ratio#%23e6333f-on-%23333
   */
  color: 'primary.light',
  //backgroundColor: 'primary.dark',
}

export const TextFieldError = styled(Typography)(({ theme }) => {
  return theme.unstable_sx({
    marginTop: '-7px',
    transform: 'translate(0, -100%)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...textFieldStyles,
  })
})
