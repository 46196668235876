import Typography, { TypographyProps } from '@mui/material/Typography'

type Props = Omit<TypographyProps<'p'>, 'component'>

export const P = ({ children, sx, ...props }: Props) => {
  return (
    <Typography
      component="p"
      sx={[{ mb: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...props}
    >
      {children}
    </Typography>
  )
}
