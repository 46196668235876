import { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import { Content, Title } from 'components'
import { ErrorStack } from './ErrorStack'

export const ErrorView = () => {
  const error = useRouteError() as Error

  useEffect(() => {
    if (error) {
      console.log('@todo log error to server ==>', {
        name: error.name,
        message: error.message,
        stack: error.stack,
      })
    }
  }, [error])

  return (
    <Content>
      <Title>Page Error</Title>
      <ErrorStack error={error} />
    </Content>
  )
}
