import { SxProps, createTheme, darken, lighten } from '@mui/material/styles'
import { red, green } from '@mui/material/colors'

/**
 * Material UI Theme
 *
 * @see https://mui.com/material-ui/customization/theming/
 */
export const theme = createTheme({
  /**
   * Typography configuration
   *
   * @see https://mui.com/material-ui/customization/typography/
   */
  typography: {
    fontFamily: '"forza", Arial, sans-serif',
    fontWeightRegular: 400,
  },
  /**
   * Palette configuration
   *
   * If you add a new palette color, you also need to add TypeScript definitions.
   * Open material-ui.d.ts and add custom color under Palette and PaletteOptions
   * and under component overrides such as AppBarPropsColorOverrides.
   *
   * @see https://mui.com/material-ui/customization/palette/#adding-new-colors
   */
  palette: {
    mode: 'light',
    primary: {
      main: '#e30614',
      light: lighten('#e30614', 0.1),
      dark: darken('#e30614', 0.1),
    },
    secondary: {
      main: green[800],
      light: green[700],
      dark: green[900],
    },
    menu: {
      main: '#e3061415',
      light: '#e3061410',
      dark: '#e3061420',
      contrastText: '#000',
    },
    darkGrey: {
      main: '#333',
      light: lighten('#333', 0.1),
      dark: darken('#333', 0.1),
      contrastText: '#fff',
    },
    lightGrey: {
      main: '#bcbcbc',
      light: lighten('#bcbcbc', 0.1),
      dark: darken('#bcbcbc', 0.1),
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    black: {
      main: '#000',
      light: lighten('#000', 0.1),
      dark: '#000',
      contrastText: '#fff',
    },
  },
  /**
   * Custom theme variables
   *
   * If you add a new theme variable, also add TypeScript definition
   * into material-ui.d.ts under Theme and ThemeOptions
   *
   * @see https://mui.com/material-ui/customization/theming/#custom-variables
   */
  container: {
    maxWidth: 1400,
  },
  drawer: {
    width: 280,
    megawidth: 400,
  },
  appbar: {
    minHeight: { xs: 56, sm: 56, md: 56 },
  },
  header: {
    minHeight: { xs: 56, sm: 64, md: 80 },
  },
  title: {
    minHeight: { xs: 56, sm: 64, md: 64 },
  },
  menu: {
    width: 300,
    fontSize: '0.85rem',
  },
})

export type Theme = typeof theme

export type StylesFn = (theme: Theme) => SxProps

export type Styles = { [key: string]: SxProps | StylesFn }

export const globalStyles = {
  '#root': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}
