import { forwardRef } from 'react'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

export type LinkProps = RouterLinkProps & Omit<MuiLinkProps, 'component'>

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { children, ...props },
  ref
) {
  return (
    <MuiLink component={RouterLink} {...props} ref={ref}>
      {children}
    </MuiLink>
  )
})
