import Box, { BoxProps } from '@mui/material/Box'

export type ResponsiveIframeProps = BoxProps<'iframe'> & {
  containerProps?: BoxProps<'div'>
}

export const ResponsiveIframe = ({
  containerProps,
  ...props
}: ResponsiveIframeProps) => {
  return (
    <Box
      component="div"
      {...containerProps}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        flex: 1,
      }}
    >
      <Box
        {...props}
        component="iframe"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 0,
        }}
      />
    </Box>
  )
}
